<template>

    
    <b-card no-body>
        <b-card-header>
            <b-card-title style="font-size: 22px;">
                Рубрики
            </b-card-title>
            <b-button @click="isOnAddState = true" variant="primary" pill>Добавить</b-button>
        </b-card-header>
        <b-table-simple>
            <b-thead>
                <b-tr>
                    <b-td>#</b-td>
                    <b-td>Название</b-td>
                    <b-td>Кол. публикаций</b-td>
                    <b-td></b-td>
                </b-tr>
            </b-thead>
            <b-tbody>
                 <b-tr v-if="isOnAddState">
                    <b-td>–</b-td>
                    <b-td colspan="2">
                        <b-form-input v-model="title" placeholder="Название рубрики"></b-form-input>
                        <b-form-input v-model="title_en" placeholder="Название рубрики (en)"></b-form-input>
                    </b-td>
                    <b-td class="text-right">
                        <b-link @click="add">
                            <feather-icon icon="CheckIcon" class="text-success" size="15" />
                        </b-link>
                        <b-link @click="isOnAddState = false" class="ml-1">
                            <feather-icon icon="XIcon" class="text-danger" size="15" />
                        </b-link>
                    </b-td>
                </b-tr>
                <b-tr v-for="(category, index) in news.categories" :key="index">
                    <template v-if="category.is_edit && editable[category.id]">
                        <b-td>{{ category.id }}</b-td>
                        <b-td colspan="2">
                            <b-form-input v-model="editable[category.id].title" placeholder="Название рубрики"></b-form-input>
                            <b-form-input v-model="editable[category.id].title_en" placeholder="Название рубрики (en)"></b-form-input>
                        </b-td>
                        <b-td class="text-right">
                            <b-link @click="update( category )">
                                <feather-icon icon="CheckIcon" class="text-success" size="15" />
                            </b-link>
                            <b-link @click="setEditMode( category, false )" class="ml-1">
                                <feather-icon icon="XIcon" class="text-danger" size="15" />
                            </b-link>
                        </b-td>
                    </template>
                    <template v-else>
                        <b-td class="text-black">{{ category.id }}</b-td>
                            <b-td class="text-black">
                                <p class="m-0">{{ category.title }} | {{ category.title_en }}</p>
                                <span class="text-muted">{{ category.slug }}</span>
                            </b-td>
                            <b-td class="text-black">
                                <b-badge>
                                    {{ category.news_count }}
                                </b-badge>
                            </b-td>
                            <b-td class="text-black text-right">
                                <b-link @click="setEditMode( category, true )">
                                    <feather-icon icon="EditIcon" class="text-primary" size="15" />
                                </b-link>
                                <b-link @click="preferDeleteAction( category, index )" class="ml-1">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="15" />
                                </b-link>
                        </b-td>
                    </template>
                </b-tr>
               
            </b-tbody>
        </b-table-simple>
    </b-card>
    
</template>

<script>

    import news from "@/modules/admin/news"
    
    export default {

        data() {
            return {
                news,
                isOnAddState: false,
                title: null,
                editable: {},
            }
        },
        methods: {
            
            setEditMode( item, val ) {
                              
               if( val ) {
                   this.$set( this.editable , item.id, {
                       id: item.id,
                       title: item.title,
                       title_en: item.title_en
                   });
               }
               
               this.$set( item, "is_edit", val ); 
               
           },
            
            add( args ) {
                this.$request.post("news/categories.add", {
                    title: this.title
                }).then( category => {
                    this.title = null;
                    this.title_en = null;
                    this.news.categories.push( category );
                    this.isOnAddState = false;
                });
            },
            
            update( category ) {
                this.$request.post("news/categories.update", {
                    id: category.id,
                    title: this.editable[category.id].title,
                    title_en: this.editable[category.id].title_en
                }).then( rsp => {
                    category.title = this.editable[category.id].title;
                    category.title_en = this.editable[category.id].title_en;
                    category.slug = rsp.slug;
                    this.setEditMode( category, false );
                });
            },
            
            preferDeleteAction( category, index ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить рубрику ${category.title}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("news/categories.delete", {
                            id: category.id
                        }).then( rsp => {
                            this.news.categories.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                title: category.title,
                                text: 'Рубрика успешно удалена',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
                
            },
            
            
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>