

    import $request from "@/modules/request/"
    
    export default {
        
        categories: [],
        getCategories() {
            $request.get("news/categories").then( rsp => {
                this.categories = rsp;
            });
        }
        
    }